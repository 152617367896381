/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  theme: "2",
}

function reducer(state, action) {
  switch (action.type) {
    case "1": {
      return {
        ...state,
        theme: '1',
      }
    }
    case "2": {
      return {
        ...state,
        theme: '2',
      }
    }
    case "3": {
      return {
        ...state,
        theme: '3',
      }
    }
    case "4": {
      return {
        ...state,
        theme: '4',
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const ThemeProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <div className={`theme-wrap theme-${state.theme}`}>
          {children}
        </div>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default ThemeProvider
